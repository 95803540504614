import React from 'react';
import { Link } from 'gatsby';
import {kebabCase} from "lodash";

const Collections = ({ collections }) => {

    // Swap first and last elements in collections array
    // Add activeStyle to first element
    collections = [collections[collections.length - 1], ...collections.slice(1, collections.length - 1), collections[0]];
    collections[0].activeClass = 'color-pink';

    return (
        <div className="categories">
            { collections.map(collection => {
                const activeClass = collection.activeClass ? collection.activeClass : '';
                return (
                    <div className="categoryTitle" key={collection.fieldValue}>
                        <Link to={`/collections/${kebabCase(collection.fieldValue)}/`}>
                            {collection.fieldValue}
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}

export default Collections;